<template>
  <div class="app-container">
    <Title :data="data"></Title>
    <div style="display: flex">
      <div style="width: 100%">
        <Batch
          title="全度教学质量评价表(专家专用)"
          text="全度教学质量评价表(专家专用)"
          :list="form.teaching_quality_evaluation_form_expert"
          @edit="clickUpload('expert')"
          style="margin-bottom: 20px"
        ></Batch>
        <Batch
          title="全度教学质量评价表(教师专用)"
          text="全度教学质量评价表(教师专用)"
          :list="form.teaching_quality_evaluation_form_teacher"
          @edit="clickUpload('teacher')"
          style="margin-bottom: 20px"
        ></Batch>
      </div>
      <!-- <Uptips style="width: 20%"></Uptips> -->
    </div>
    <div class="bottom_btn">
      <el-button @click="$router.go(-1)">返回</el-button>
      <!-- <el-button type="primary" @click="submit" :loading="btnLoading">提交</el-button> -->
    </div>
  </div>
</template>

<script>
// import BatchAdd from "@/components/Batch/index.vue";
import batchadd from "@/components/Batch_add/index.vue";
import request from "@/utils/request";
export default {
  components: {
    batchadd,
  },
  data() {
    return {
      selected: false,
      btnLoading: false,
      expertList: [],
      teacherList: [],
      listQuery: {
        type: 6,
        class_course_id: "",
      },
      data: {},
      form: {},
    };
  },
  created() {
    this.listQuery.class_course_id = this.$route.query.class_course_id;
    this.data = JSON.parse(this.$route.query.data);
    localStorage.setItem("classdata", JSON.stringify(this.data));
    this.form = {
      teaching_quality_evaluation_form_expert: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_quality_evaluation_form_expert",
        data: [],
      },
      teaching_quality_evaluation_form_teacher: {
        id: null,
        class_course_id: this.listQuery.class_course_id,
        type: this.listQuery.type,
        type_key: "teaching_quality_evaluation_form_teacher",
        data: [],
      },
    };
    this.getDetail();
  },
  methods: {
    clickUpload(e) {
      switch (e) {
        case "expert":
          this.form.teaching_quality_evaluation_form_expert.is_store = 1;
          break;
        case "teacher":
          this.form.teaching_quality_evaluation_form_teacher.is_store = 1;
          break;
      }
    },
    getDetail() {
      request({
        url: "/api/schoolend/eduAdmin/dataDetail",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        if (response.data.teaching_quality_evaluation_form_expert)
          this.form.teaching_quality_evaluation_form_expert =
            response.data.teaching_quality_evaluation_form_expert;
        if (response.data.teaching_quality_evaluation_form_teacher)
          this.form.teaching_quality_evaluation_form_teacher =
            response.data.teaching_quality_evaluation_form_teacher;
      });
    },
    submit() {
      let submitForm = {};
      if (this.form.teaching_quality_evaluation_form_expert.is_store) {
        submitForm.teaching_quality_evaluation_form_expert =
          this.form.teaching_quality_evaluation_form_expert;
      }
      if (this.form.teaching_quality_evaluation_form_teacher.is_store) {
        submitForm.teaching_quality_evaluation_form_teacher =
          this.form.teaching_quality_evaluation_form_teacher;
      }
      if (Object.keys(submitForm).length == 0) {
        return;
      }
      // return
      this.btnLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/dataStore",
        method: "post",
        data: submitForm,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.$router.go(0);
          // this.getDetail();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.content {
  width: 50%;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
.bottom_btn {
  display: flex;
  justify-content: center;
}
</style>
